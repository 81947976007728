import React from 'react'
import styled from '@emotion/styled'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { mq, rem } from '../utils/styles'

const Container = styled.div`
  align-items: center;
  display: flex;
  height: calc(100vh - 80px);
  width: 100%;
`

const Inner = styled.div`
  padding-bottom: 65px;
`

const Heading = styled.h2`
  font-size: ${rem(64)};
  letter-spacing: 0.1rem;
  line-height: 1;

  ${mq.lessThan('tablet')} {
    font-size: ${rem(50)};
  }

  ${mq.lessThan('mobile')} {
    font-size: ${rem(29)};
  }
`

const SubHeading = styled.p`
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.07rem;
  margin-top: 6px;

  ${mq.lessThan('mobile')} {
    font-size: ${rem(15)};
  }
`

const IndexPage = () => (
  <Layout layout="home">
    <SEO title="Home" />
    <Container>
      <Inner>
        <Heading>
          Hello, I&#39;m
          <br />
          YUSUKE IYONAGA
        </Heading>
        <SubHeading>Front-end developper</SubHeading>
      </Inner>
    </Container>
  </Layout>
)

export default IndexPage
